<template>
  <div class="content-box">
    <div class="inner">
      <div class="meta-box">
        <Card>
          <template v-slot:header>
            <img v-if="account && idPassed" src="@/assets/images/dialog_true.png" alt />
            <img v-else src="@/assets/images/smile-icon.png" alt />
            <span>
              {{
                $t('register.finishPage.title', {
                  individualUserName: individualUserName,
                  platformName: $config.info.fullName
                })
              }}&nbsp;&nbsp;
            </span>
          </template>
          <div
            v-if="account && idPassed"
            v-html="$t('register.finishPage.contentDefault', { account: account, email: email })"
          ></div>

          <!-- id3沒過，有上傳文件，有帳號 -->
          <div v-else-if="account" v-html="$t('register.finishPage.contentWithoutId3WithAccount')"></div>

          <!-- id3沒過，有上傳文件，沒有帳號 -->
          <div v-else v-html="$t('register.finishPage.contentWithoutId3WithoutAccount')"></div>

          <!-- id3沒過，沒有上傳文件，有帳號 -->
          <!-- 跳轉HOME PAGE -->

          <!-- id3沒過，沒有上傳文件，沒有帳號 -->
          <!-- 跳轉HOME PAGE -->

          <div class="btn-box">
            <router-link v-if="account" :to="{ name: 'depositFunds' }" class="el-button btn-red">
              {{ $t('register.btn.fundnow') }}
            </router-link>
            <router-link v-else :to="{ name: 'home' }" class="el-button btn-red">
              {{ $t('common.button.bkToHm') }}
            </router-link>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card';
import { apiID3Status } from '@/resource/register';
import { apiGetMetaTraderAccounts } from '@/resource';

export default {
  data() {
    return {
      account: '',
      idPassed: false
    };
  },
  mounted() {
    apiID3Status().then(resp => {
      if (resp.data.code == 0 && resp.data.data) {
        this.idPassed = true;
        this.$store.commit('common/setPendingIDProofApproval', false);
        this.$store.commit('common/setRequireIDProof', false);
      }
      if (this.requireIDProof && !this.idPassed) this.$router.push('/home');
    });
    apiGetMetaTraderAccounts().then(resp => {
      if (resp.data && resp.data.length == 1 && resp.data[0].mt4_account) {
        this.account = resp.data[0].mt4_account;
        this.$store.commit('common/setOpenAccountAction', true);
      }
    });
  },
  computed: {
    individualUserName() {
      return this.$store.state.common.individualUserName;
    },
    email() {
      return this.$store.state.common.email;
    },
    requireIDProof() {
      return this.$store.state.common.requireIDProof;
    }
  },
  components: { Card }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/authority.scss';
@import '@/assets/css/pages/register/registerFinish.scss';
</style>
